import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';

import { TranslationService } from '../services/translation.service';
import { DataCacheService } from '../services/datacache.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

  layoutSub: Subscription;
  isOpen = false;
  details = [];

  ngOnInit() {

  }

  constructor(
    private layoutService: LayoutService,
    private auth: AuthService,
    private lang: TranslationService,
    private dcs: DataCacheService
  ) {
    this.details = JSON.parse(this.dcs.load('details'));
    this.layoutSub = layoutService.toggleNotiSidebar$.subscribe(
      open => {
        this.isOpen = open;
      });
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  translate(str: string) {
    return this.lang.translate(str);
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

  getSubType() {
    const perms = JSON.parse(this.dcs.load("permissions"));
    if (perms["erp"]['pro']) {
      return 'pro';
    } else if (perms["erp"]['basic']) {
      return 'basic';
    } else {
      return 'light';
    }
  }

}
