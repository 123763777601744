<!-- Seperate Page View for 2. overduewarning -->
<section class="container-fluid"
  *ngIf="this.mode == 'view' && this.type == 'overduewarnings' && this.inputData.get('warningText2').value && this.inputData.get('warningDate2').value">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content p-3">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <img class="thumbnail" [src]="this.companyDetails['logo']" alt="Logo"
                  *ngIf="this.headerContent('left') == '#[logo]'">
                <p [ngClass]="this.headerAlignment('left')" class="pre-wrap"
                  *ngIf="this.headerContent('left') != '#[logo]'">
                  {{ this.headerContent("left") }}
                </p>
              </div>
              <div class="col-6">
                <img [src]="this.companyDetails['logo']" alt="Logo" *ngIf="this.headerContent('right') == '#[logo]'">
                <p [ngClass]="this.headerAlignment('right')" class="pre-wrap"
                  *ngIf="this.headerContent('right') != '#[logo]'">
                  {{ this.headerContent("right") }}
                </p>
              </div>
            </div>
            <hr [style]="this.spacerColor()">
          </div>
          <div class="card-body pb-0">
            <!-- Invoice Customer Details starts -->
            <div id="invoice-customer-details" class="row">
              <div class="col-12 text-left">
                <p class="text-muted mb-1 text-custom">
                  {{ companyDetails["company_name"] }} | {{ companyDetails["company_adresse"] }} | {{
                  companyDetails["company_plz"] }} {{ companyDetails["company_ort"] }}
                </p>
              </div>

              <!-- Kundendaten -->
              <div class="col-md-6 col-12 mb-5">
                <ul class="m-0 list-unstyled">
                  <li class="text-bold-800">
                    {{ this.inputData.get("name")?.value }}
                  </li>
                  <li *ngIf="this.inputData.get('forSale')?.value != '' &&
                      this.inputData.get('forSale')?.value != null">
                    z.H.
                    <span *ngIf="this.inputData.get('salutation')?.value &&
                        this.inputData.get('salutation')?.value != 'Keine' &&
                        this.inputData.get('salutation')?.value != null">{{ this.inputData.get("salutation")?.value
                      }}</span> {{ this.inputData.get("forSale")?.value }}
                  </li>
                  <li>{{ this.inputData.get("adress")?.value?.street }}</li>
                  <li>
                    {{ this.inputData.get("adress")?.value?.zip }} {{ this.inputData.get("adress")?.value?.city }}
                  </li>
                  <li>
                    {{ this.inputData.get("adress")?.value?.country }}
                  </li>
                </ul>
              </div>

              <div class="col-md-6 col-12 text-right">

                <p>
                  <span class="text-muted">{{ this.translate('module-accounting-invoices-billnumber') }}:</span> {{
                  this.inputData.get("revenueNumber")?.value }}<br>
                  <span class="text-muted">{{ this.translate('bill-amount') }}:</span> {{
                  this.inputData.get('overdueInvoiceAmount').value | number:'1.2-2' }}€<br>
                  <span class="text-muted">{{ this.translate('module-accounting-invoices-date') }}:</span> {{
                  this.inputData.get('date').value }}<br>
                  <br>
                  <span class="text-muted">{{ this.translate('date') }}:</span> {{
                  this.inputData.get("warningDate2")?.value
                  | date :
                  "dd.MM.yyyy" }}<br>
                  <span class="text-muted">{{ this.translate('module-accounting-payment-reminders-paymenttarget')
                    }}:</span> {{
                  this.inputData.get("paymentTarget2")?.value
                  | date :
                  "dd.MM.yyyy" }}

                  <span *ngIf="this.inputData.get('orderNumber')?.value">
                    <br><span class="text-muted">{{ this.translate("module-orders-ordernumber") }}:</span> {{
                    this.inputData.get("orderNumber")?.value }}
                  </span>
                </p>

                <!-- UID & Bearbeiter-->
                <p>
                  <span class="text-muted">{{ this.translate("uid") }} :</span> {{ this.inputData.get("uid")?.value ==
                  "Nicht angegeben" || this.inputData.get("uid")?.value == "" ? this.translate("not-provided") :
                  this.inputData.get("uid")?.value }}
                  <br>
                  <span class="text-muted">{{ this.translate("editor") }}:</span> {{ this.inputData.get("editor")?.value
                  }}<br>
                  <span class="text-muted">E-Mail: </span>
                  <a [href]="'mailto:' + this.inputData.get('editormail')?.value">
                    {{ this.inputData.get("editormail")?.value }}
                  </a>
                </p>
              </div>
            </div>
            <!-- Invoice Customer Details ends -->
            <!-- Invoice Items Details starts -->
            <div id="invoice-items-details">

              <h2 class="primary text-uppercase title m-2 ml-0">
                2. {{
                this.translate("module-accounting-overduewarning") }}
              </h2>
              <p class="pre-wrap" *ngIf="this.inputData.get('warningText2').value">
                {{ this.inputData.get("warningText2")?.value }}
              </p>

              <!-- Fußnote -->
              <div class="row mt-3 mt-md-0" *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                <div class="col-md-6 col-12 text-left mt-4">
                  <p class="pre-wrap" *ngIf="this.type == 'offers'">
                    {{ this.inputData.get("footnote")?.value }}
                  </p>
                  <p class="pre-wrap" *ngIf="this.type == 'deliverynotes'">
                    {{ this.inputData.get("deliveryTerms")?.value }}
                  </p>
                  <p class="pre-wrap" *ngIf="this.type == 'revenues'">
                    {{ this.inputData.get("terms")?.value }}
                    <span class="pre-wrap text-muted" *ngIf="this.skonto > 0">
                      <br>{{ this.getSkontoText() }}
                    </span>
                  </p>
                </div>
                <div class="col-md-6 col-12">
                  <div class="table-responsive" *ngIf="this.type != 'deliverynotes'">
                    <table class="table">
                      <tbody>
                        <tr class="nb line-top" *ngIf="this.getDiscount() > 0">
                          <td class="text-bold-500">{{ this.translate("sub-total") }}</td>
                          <td class="text-right">
                            {{ this.getTotal() | number : "1.2-2" }}€
                          </td>
                        </tr>
                        <tr class="nb" *ngIf="this.getDiscount() > 0">
                          <td>{{ this.translate("discount") }}</td>
                          <td class="text-right">
                            {{ this.getDiscount() | number : "1.2-2" }}€
                          </td>
                        </tr>
                        <tr class="nb line-top" *ngIf="this.inputData.get('tax')?.value == true">
                          <td class="text-bold-500">{{ this.translate("net") }}</td>
                          <td class="text-right">
                            {{ this.getNetto() | number : "1.2-2" }}€
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.calcWithSkonto(this.getNetto())
                              | number : "1.2-2"
                              }}€
                            </small>
                          </td>
                        </tr>
                        <tr class="nb" *ngFor="let steuer of this.getIncludedTaxes() | keyvalue">
                          <td *ngIf="this.inputData.get('tax')?.value == true">
                            {{ this.translate("tax") }}
                            <small>{{ steuer.key }}%</small>
                          </td>
                          <td class="text-right" *ngIf="this.inputData.get('tax')?.value == true">
                            {{ steuer.value | number : "1.2-2" }}€
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.calcWithSkonto(steuer.value)
                              | number : "1.2-2"
                              }}€
                            </small>
                          </td>
                        </tr>
                        <tr class="nb line-top text-bold-500">
                          <td>
                            {{ this.translate("bill-amount") }}
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.translate("with") +
                              " " +
                              this.translate("skonto")
                              }}
                            </small>
                          </td>
                          <td class="text-right">
                            {{ this.getBrutto() | number : "1.2-2" }}€
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.calcWithSkonto(this.getBrutto())
                              | number : "1.2-2"
                              }}€
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Invoice Items Details ends -->
          </div>

          <div class="card-footer">
            <hr [style]="this.spacerColor()">
            <div class="row">
              <div class="col-4">
                <p [ngClass]="this.footerAlignment('left')" class="pre-wrap">{{ this.footerContent("left").replace(
                  "#[seiten]", "Seite 1 von 1" ) }}
                </p>
              </div>
              <div class="col-4">
                <p [ngClass]="this.footerAlignment('center')" class="pre-wrap">{{ this.footerContent("center").replace(
                  "#[seiten]", "Seite 1 von 1" ) }}
                </p>
              </div>
              <div class="col-4">
                <p [ngClass]="this.footerAlignment('right')" class="pre-wrap">{{ this.footerContent("right").replace(
                  "#[seiten]", "Seite 1 von 1" ) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Primary Page View -->
<section class="container-fluid" *ngIf="this.mode == 'view'">
  <div class="row" [ngClass]="this.dcs.getMenuPosition() == 'Top' ? 'float' : 'float-2'">
    <div class="col-12 text-sm-right">
      <a id="cypress-back" [ngbTooltip]="this.translate('back')" placement="bottom" (click)="this.goBack()"
        class="btn btn-icon round gradient-blackberry mr-1">
        <i class="ft-arrow-left"></i>
      </a>

      <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="this.type == 'offers'">
        <button class="btn btn-icon round gradient-purple-bliss mr-1" id="moreActionsOffers"
          [ngbTooltip]="this.translate('more-actions')" placement="bottom" ngbDropdownToggle>
          <i class="ft-more-horizontal"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="moreActions">
          <a id="cypress-showOrder" class="dropdown-item w100" (click)="this.navigate(
                '/aufträge',
                this.inputData.get('orderNumber')?.value
              )" *ngIf="this.inputData.get('orderNumber')?.value &&
              this.hasPermission('erp-orders-order-read')">
            <i class="ft-file-text"></i> {{ this.translate("module-orders-show") }}
          </a>
          <a id="cypress-createOrder" class="dropdown-item w100" (click)="this.navigate(
                '/aufträge/erstellen',
                this.inputData.get('offerNumber')?.value
              )" *ngIf="!this.inputData.get('orderNumber')?.value &&
              this.hasPermission('erp-orders-order-create') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
            <i class="ft-file-plus"></i> {{ this.translate("module-orders-create") }}
          </a>
          <a id="cypress-download" class="dropdown-item w100" (click)="download('offers')"
            *ngIf="this.hasPermission('erp-accounting-offers-download')">
            <i class="ft-download-cloud"></i> {{ this.translate("download") }}
          </a>
          <a class="dropdown-item w100" (click)="this.toggleMode('edit')" *ngIf="!this.inputData.get('orderNumber')?.value &&
              this.hasPermission('erp-accounting-offers-edit')">
            <i class="ft-edit"></i> {{ this.translate("module-accounting-offers-edit") }}
          </a>
          <a id="cypress-delete" class="dropdown-item w100" (click)="this.openPopup(deletePopupOffers)" *ngIf="!this.inputData.get('orderNumber')?.value &&
              this.hasPermission('erp-accounting-offers-delete')">
            <i class="ft-trash-2"></i> {{ this.translate("module-accounting-offers-delete") }}
          </a>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="this.type == 'revenues'">
        <button class="btn btn-icon round gradient-purple-bliss mr-1" id="moreActionsRevenues"
          [ngbTooltip]="this.translate('more-actions')" placement="bottom" ngbDropdownToggle>
          <i class="ft-more-horizontal"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="moreActions">
          <a id="cypress-download" class="dropdown-item w100" (click)="download('revenues')"
            *ngIf="this.hasPermission('erp-accounting-revenues-download')">
            <i class="ft-download-cloud"></i> {{ this.translate("module-accounting-invoices-download-bill") }}
          </a>
          <a id="cypress-downloadCopy" class="dropdown-item w100" (click)="download('revenuescopy')"
            *ngIf="this.hasPermission('erp-accounting-revenues-download')">
            <i class="ft-download-cloud"></i> {{ this.translate("module-accounting-invoices-download-copy") }}
          </a>
          <a id="cypress-showOrder" class="dropdown-item w100" (click)="this.navigate(
                          '/aufträge',
                          this.inputData.get('orderNumber')?.value
                        )"
            *ngIf="this.inputData.get('orderNumber')?.value &&
                        this.hasPermission('erp-orders-order-read') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
            <i class="ft-file-text"></i> {{ this.translate("module-orders-show") }}
          </a>
          <a id="cypress-editRevenue" class="dropdown-item w100" *ngIf="!this.inputData.get('locked')?.value && this.inputData.get('status').value == 0 &&
              this.hasPermission('erp-accounting-revenues-edit')" (click)="this.toggleMode('edit')">
            <i class="ft-edit"></i> {{ this.translate("module-accounting-invoices-edit") }}
          </a>
          <a id="cypress-createPaymentReminder" class="dropdown-item w100"
            *ngIf="!this.inputData.get('locked')?.value && !this.inputData.get('paymentreminderID').value &&
                        this.hasPermission('erp-accounting-paymentreminders-create') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))"
            (click)="this.navigate('/buchhaltung/zahlungserinnerungen/erstellen', this.inputData.get('revenueNumber').value)">
            <i class="ft-info"></i> {{ this.translate("module-accounting-payment-reminders-create") }}
          </a>
          <a id="cypress-showPaymentReminder" class="dropdown-item w100"
            *ngIf="this.inputData.get('paymentreminderID').value &&
                                  this.hasPermission('erp-accounting-paymentreminders-read') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))"
            (click)="this.navigate('/buchhaltung/zahlungserinnerungen', this.inputData.get('paymentreminderID').value)">
            <i class="ft-info"></i> {{ this.translate("module-accounting-payment-reminders-show") }}
          </a>

          <a id="cypress-createOverdueWarning" class="dropdown-item w100"
            *ngIf="!this.inputData.get('locked')?.value && !this.inputData.get('overduewarningID').value && this.inputData.get('reminderDate').value && this.hasPermission('erp-accounting-overduewarnings-create') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))"
            (click)="this.navigate('/buchhaltung/mahnungen/erstellen', this.inputData.get('revenueNumber').value)">
            <i class="ft-alert-triangle"></i> {{ this.translate("module-accounting-overduewarnings-create") }}
          </a>
          <a id="cypress-ShowOverdueWarning" class="dropdown-item w100"
            *ngIf="this.inputData.get('overduewarningID').value && this.hasPermission('erp-accounting-overduewarnings-read') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))"
            (click)="this.navigate('/buchhaltung/mahnungen', this.inputData.get('overduewarningID').value)">
            <i class="ft-alert-triangle"></i> {{ this.translate("module-accounting-overduewarnings-show") }}
          </a>

          <a id="cypress-setRevenuePayed" class="dropdown-item w100"
            *ngIf="!this.inputData.get('locked')?.value && this.hasPermission('erp-accounting-revenues-edit')"
            (click)="this.openPopup(payment)">
            <i class="ft-save"></i> {{ this.translate("module-accounting-invoices-set-payed") }}
          </a>
          <a id="cypress-cancleRevenue" class="dropdown-item w100"
            *ngIf="!this.inputData.get('locked')?.value && this.hasPermission('erp-accounting-revenues-delete')"
            (click)="this.openPopup(cancleInvoice)">
            <i class="ft-x-circle"></i> {{ this.translate("module-accounting-invoices-cancel") }}
          </a>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="this.type == 'orders'">
        <button class="btn btn-icon round gradient-purple-bliss mr-1" id="moreActionsOrders"
          [ngbTooltip]="this.translate('more-actions')" placement="bottom" ngbDropdownToggle>
          <i class="ft-more-horizontal"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="moreActions">
          <a id="cypress-download" class="dropdown-item w100" (click)="download('orders')"
            *ngIf="this.hasPermission('erp-orders-orderconfirmation-download')">
            <i class="ft-download-cloud"></i> {{ this.translate("module-orders-download") }}
          </a>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="this.type == 'deliverynotes'">
        <button class="btn btn-icon round gradient-purple-bliss mr-1" id="moreActionsOffers"
          [ngbTooltip]="this.translate('more-actions')" placement="bottom" ngbDropdownToggle>
          <i class="ft-more-horizontal"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="moreActions">
          <a id="cypress-showOrder" class="dropdown-item w100" (click)="this.navigate(
                '/aufträge',
                this.inputData.get('orderNumber')?.value
              )" *ngIf="this.inputData.get('orderNumber')?.value &&
              this.hasPermission('erp-orders-order-read')">
            <i class="ft-file-text"></i> {{ this.translate("module-orders-show") }}
          </a>
          <a id="cypress-download" class="dropdown-item w100" (click)="download('deliverynotes')"
            *ngIf="this.hasPermission('erp-inventory-deliverynotes-download')">
            <i class="ft-download-cloud"></i> {{ this.translate("module-storage-deliverynotes-download") }}
          </a>
          <a id="cypress-deleteNote" class="dropdown-item w100" *ngIf="!this.inputData.get('locked')?.value &&
                        this.hasPermission('erp-inventory-deliverynotes-delete')" (click)="this.openPopup(deleteNote)">
            <i class="ft-trash-2"></i> {{ this.translate("module-storage-deliverynotes-delete") }}
          </a>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="this.type == 'paymentreminders'">
        <button class="btn btn-icon round gradient-purple-bliss mr-1" id="moreActionsOffers"
          [ngbTooltip]="this.translate('more-actions')" placement="bottom" ngbDropdownToggle>
          <i class="ft-more-horizontal"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="moreActions">
          <a id="cypress-showRevenue" class="dropdown-item w100"
            (click)="this.navigate('/buchhaltung/einnahmen', this.inputData.get('revenueNumber')?.value)"
            *ngIf="this.inputData.get('revenueNumber')?.value && this.hasPermission('erp-accounting-revenues-read')">
            <i class="ft-file-text"></i> {{ this.translate("module-accounting-invoices-show") }}
          </a>
          <a id="cypress-download" class="dropdown-item w100" (click)="download('paymentreminders')"
            *ngIf="this.hasPermission('erp-accounting-paymentreminders-download')">
            <i class="ft-download-cloud"></i> {{ this.translate("download") }}
          </a>
          <a id="cypress-delete" class="dropdown-item w100" (click)="this.openPopup(deletePopupReminders)"
            *ngIf="this.hasPermission('erp-accounting-paymentreminders-delete')">
            <i class="ft-trash-2"></i> {{ this.translate("module-accounting-payment-reminders-delete") }}
          </a>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block" placement="bottom-right" *ngIf="this.type == 'overduewarnings'">
        <button class="btn btn-icon round gradient-purple-bliss mr-1" id="moreActionsOffers"
          [ngbTooltip]="this.translate('more-actions')" placement="bottom" ngbDropdownToggle>
          <i class="ft-more-horizontal"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="moreActions">
          <a id="cypress-showRevenue" class="dropdown-item w100"
            (click)="this.navigate('/buchhaltung/einnahmen', this.inputData.get('revenueNumber')?.value)"
            *ngIf="this.inputData.get('revenueNumber')?.value && this.hasPermission('erp-accounting-revenues-read')">
            <i class="ft-file-text"></i> {{ this.translate("module-accounting-invoices-show") }}
          </a>
          <a id="cypress-downloadFirst" class="dropdown-item w100" (click)="download('overduewarnings')"
            *ngIf="this.hasPermission('erp-accounting-overduewarnings-download')">
            <i class="ft-download-cloud"></i> 1. {{ this.translate("module-accounting-overduewarning") }} {{
            this.translate("download") }}
          </a>
          <a id="cypress-downloadSecond" class="dropdown-item w100" (click)="download('overduewarnings2')"
            *ngIf="this.inputData.get('warningDate2')?.value && this.hasPermission('erp-accounting-overduewarnings-download')">
            <i class="ft-download-cloud"></i> 2. {{ this.translate("module-accounting-overduewarning") }} {{
            this.translate("download") }}
          </a>
          <a id="cypress-upgradeOverduewarnings" class="dropdown-item w100"
            (click)="this.openWarningPopup(overdueUpgrade)"
            *ngIf="!this.inputData.get('locked').value && !this.inputData.get('warningText2').value && !this.inputData.get('warningDate2').value && this.hasPermission('erp-accounting-overduewarnings-edit')">
            <i class="ft-alert-triangle"></i> {{ this.translate("module-accounting-overduewarnings-upgrade") }}
          </a>
          <a id="cypress-delete" class="dropdown-item w100" (click)="this.openPopup(deletePopupWarnings)"
            *ngIf="!this.inputData.get('locked').value && this.hasPermission('erp-accounting-overduewarnings-delete')">
            <i class="ft-trash-2"></i> {{ this.translate("module-accounting-overduewarnings-delete") }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content p-3">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <img class="thumbnail" [src]="this.companyDetails['logo']" alt="Logo"
                  *ngIf="this.headerContent('left') == '#[logo]'">
                <p [ngClass]="this.headerAlignment('left')" class="pre-wrap"
                  *ngIf="this.headerContent('left') != '#[logo]'">
                  {{ this.headerContent("left") }}
                </p>
              </div>
              <div class="col-6">
                <img [src]="this.companyDetails['logo']" alt="Logo" *ngIf="this.headerContent('right') == '#[logo]'">
                <p [ngClass]="this.headerAlignment('right')" class="pre-wrap"
                  *ngIf="this.headerContent('right') != '#[logo]'">
                  {{ this.headerContent("right") }}
                </p>
              </div>
            </div>
            <hr [style]="this.spacerColor()">
          </div>

          <div class="card-body pb-0">
            <!-- Invoice Customer Details starts -->
            <div id="invoice-customer-details" class="row">
              <div class="col-12 text-left">
                <p class="text-muted mb-1 text-custom">
                  {{ companyDetails["company_name"] }} | {{ companyDetails["company_adresse"] }} | {{
                  companyDetails["company_plz"] }} {{ companyDetails["company_ort"] }}
                </p>
              </div>

              <!-- Kundendaten -->
              <div class="col-md-6 col-12 mb-5">
                <ul class="m-0 list-unstyled">
                  <li class="text-bold-800">
                    {{ this.inputData.get("name")?.value }}
                  </li>
                  <li *ngIf="this.inputData.get('forSale')?.value != '' &&
                      this.inputData.get('forSale')?.value != null">
                    z.H.
                    <span *ngIf="this.inputData.get('salutation')?.value &&
                        this.inputData.get('salutation')?.value != 'Keine' &&
                        this.inputData.get('salutation')?.value != null">{{ this.inputData.get("salutation")?.value
                      }}</span> {{ this.inputData.get("forSale")?.value }}
                  </li>
                  <li>{{ this.inputData.get("adress")?.value?.street }}</li>
                  <li>
                    {{ this.inputData.get("adress")?.value?.zip }} {{ this.inputData.get("adress")?.value?.city }}
                  </li>
                  <li>{{ this.inputData.get("adress")?.value?.country }}</li>
                </ul>
              </div>

              <div class="col-md-6 col-12 text-right">
                <div class="mb-3 badge badge-pill" *ngIf="this.type == 'offers'" [ngClass]="this.inputData.get('orderNumber')?.value
                      ? 'bg-light-success'
                      : 'bg-light-info'">
                  {{ this.inputData.get("orderNumber")?.value ? this.translate("module-orders-created") :
                  this.translate("open") }}
                </div>

                <div class="mb-3 badge badge-pill" *ngIf="this.type == 'revenues'"
                  [ngClass]="this.inputData.get('status')?.value == 0 ? 'bg-light-info' : (this.inputData.get('status')?.value == 1 ? 'bg-light-success' : (this.inputData.get('status')?.value == 2 ? 'bg-light-warning' :
                  (this.inputData.get('status')?.value == 3 ? 'bg-light-danger' : (this.inputData.get('status')?.value == 4 ? 'bg-light-success':'bg-light-info'))))">
                  <i class="ft-lock" *ngIf="this.inputData.get('locked')?.value"></i>
                  {{ this.getStatusBadgeText(this.inputData.get('status')?.value) }}
                </div>

                <p>
                  <span *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                    <span class="text-muted">{{ this.translate("date") }}:</span> {{ this.inputData.get("date")?.value |
                    date : "dd.MM.yyyy" }}
                  </span>

                  <span *ngIf="this.type == 'paymentreminders'">
                    <span class="text-muted">{{ this.translate("module-accounting-invoices-billnumber") }}:</span> {{
                    this.inputData.get("revenueNumber")?.value }}<br>
                    <span class="text-muted">{{ this.translate("date") }}:</span> {{
                    this.inputData.get("reminderDate")?.value
                    |
                    date : "dd.MM.yyyy" }}
                  </span>

                  <span *ngIf="this.type == 'overduewarnings'">
                    <span class="text-muted">{{ this.translate('module-accounting-invoices-billnumber') }}:</span> {{
                    this.inputData.get("revenueNumber")?.value }}<br>
                    <span class="text-muted">{{ this.translate('bill-amount') }}:</span> {{
                    this.inputData.get('overdueInvoiceAmount').value | number:'1.2-2' }}€<br>
                    <span class="text-muted">{{ this.translate('module-accounting-invoices-date') }}:</span> {{
                    this.inputData.get('date').value }}<br>
                    <br>
                    <span class="text-muted">{{ this.translate('date') }}:</span> {{
                    this.inputData.get("warningDate")?.value
                    | date :
                    "dd.MM.yyyy" }}<br>
                    <span class="text-muted">{{ this.translate('module-accounting-payment-reminders-paymenttarget')
                      }}:</span> {{
                    this.inputData.get("paymentTarget")?.value
                    | date :
                    "dd.MM.yyyy" }}
                  </span>

                  <span *ngIf="this.type == 'offers'">
                    <br><span class="text-muted">{{
                      this.translate("module-accounting-offers-valid-til")
                      }}:</span> {{ this.inputData.get("validTil")?.value | date : "dd.MM.yyyy" }}
                  </span>

                  <span *ngIf="this.inputData.get('orderNumber')?.value">
                    <br><span class="text-muted">{{ this.translate("module-orders-ordernumber") }}:</span> {{
                    this.inputData.get("orderNumber")?.value }}
                  </span>
                </p>

                <!-- UID & Bearbeiter-->
                <p>
                  <span class="text-muted">{{ this.translate("uid") }} :</span> {{ this.inputData.get("uid")?.value ==
                  "Nicht angegeben" || this.inputData.get("uid")?.value == "" ? this.translate("not-provided") :
                  this.inputData.get("uid")?.value }}
                  <br>
                  <span class="text-muted">{{ this.translate("editor") }}:</span> {{ this.inputData.get("editor")?.value
                  }}<br>
                  <span class="text-muted">E-Mail: </span>
                  <a [href]="'mailto:' + this.inputData.get('editormail')?.value">
                    {{ this.inputData.get("editormail")?.value }}
                  </a>
                </p>
              </div>
            </div>
            <!-- Invoice Customer Details ends -->
            <!-- Invoice Items Details starts -->
            <div id="invoice-items-details">
              <div *ngIf="this.type == 'revenues'">
                <h2 class="primary text-uppercase title m-2 ml-0">
                  {{ this.inputData.get("type")?.value == 1 ? this.translate("module-accounting-invoice") :
                  this.translate("module-accounting-invoice-cancellation") }} {{
                  this.inputData.get("revenueNumber")?.value }}
                </h2>
                <p class="pre-wrap">
                  {{ this.inputData.get("revenuetext")?.value }}
                </p>
              </div>

              <div *ngIf="this.type == 'offers'">
                <h2 class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-accounting-offer") }} {{ this.number }}
                </h2>

                <p class="pre-wrap">
                  {{ this.inputData.get("offertext")?.value }}
                </p>
              </div>

              <div *ngIf="this.type == 'orders'">
                <h2 class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-orders-orderconfirmation") }}
                </h2>

                <p class="pre-wrap">
                  {{ this.inputData.get("orderText")?.value }}
                </p>
              </div>

              <div *ngIf="this.type == 'deliverynotes'">
                <h2 class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-storage-deliverynote") }} {{ this.inputData.get("date")?.value | date :
                  "yyyyMMdd" }}{{ this.number }}
                </h2>
                <p class="pre-wrap">
                  {{ this.inputData.get("deliveryText")?.value }}
                </p>
              </div>

              <div *ngIf="this.type == 'paymentreminders'">
                <h2 class="primary text-uppercase title m-2 ml-0">
                  {{ this.translate("module-accounting-payment-reminder") }}
                </h2>
                <p class="pre-wrap">
                  {{ this.inputData.get("reminderText")?.value }}
                </p>
              </div>

              <div *ngIf="this.type == 'overduewarnings'">
                <h2 class="primary text-uppercase title m-2 ml-0">
                  1. {{
                  this.translate("module-accounting-overduewarning") }}
                </h2>
                <p class="pre-wrap">
                  {{ this.inputData.get("warningText")?.value }}
                </p>
              </div>

              <div class="row" *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                <div class="table-responsive col-12 overflow-inherit">
                  <table class="table mt-3">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th *ngIf="this.type == 'deliverynotes'">
                          {{ this.translate("module-storage-productnumber") }}
                        </th>
                        <th>
                          {{ this.translate("module-storage-product") }}
                        </th>
                        <th class="text-right">{{ this.translate("count") }}</th>
                        <th class="text-right" *ngIf="this.type != 'deliverynotes'">
                          {{ this.translate("price") }}
                          <small><br>{{ this.translate("per-unit") }}</small>
                        </th>
                        <th class="text-right" *ngIf="this.getDiscount() > 0 &&
                            this.type != 'deliverynotes'">
                          {{ this.translate("discount") }}
                        </th>
                        <th class="text-right" *ngIf="this.inputData.get('tax')?.value == true &&
                            this.type != 'deliverynotes'">
                          {{ this.translate("tax") }}
                        </th>
                        <th class="text-right" *ngIf="this.type != 'deliverynotes'">
                          {{ this.translate("total") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let artikel of this.inputData?.get('products')?.value; let i = index">
                        <td class="w-5" *ngIf="artikel.type == 'product'">
                          {{ this.getPos(i) + 1 }}.
                        </td>
                        <td class="w-5" *ngIf="artikel.type == 'postext' ||
                            artikel.type == 'pagebreak'"></td>

                        <td class="w-45" *ngIf="artikel.type == 'postext'">
                          {{ artikel.value }}
                        </td>
                        <td class="w-45" *ngIf="artikel.type == 'pagebreak'">
                          {{ this.translate("pagebreak") }}
                        </td>

                        <td class="w-10" *ngIf="(artikel.type == 'postext' ||
                              artikel.type == 'pagebreak') &&
                            this.inputData.get('tax')?.value == true"></td>
                        <td class="w-10" *ngIf="(artikel.type == 'postext' ||
                              artikel.type == 'pagebreak') &&
                            this.inputData.get('tax')?.value == true"></td>
                        <td class="w-15" *ngIf="(artikel.type == 'postext' ||
                              artikel.type == 'pagebreak') &&
                            this.type != 'deliverynotes'"></td>
                        <td *ngIf="(artikel.type == 'postext' ||
                              artikel.type == 'pagebreak') &&
                            this.type != 'deliverynotes' &&
                            this.getDiscount() > 0"></td>
                        <td class="w-5" *ngIf="(artikel.type == 'postext' ||
                              artikel.type == 'pagebreak') &&
                            this.type != 'deliverynotes'"></td>
                        <td class="w-10" *ngIf="(artikel.type == 'postext' ||
                              artikel.type == 'pagebreak') &&
                            this.type != 'deliverynotes'"></td>

                        <td class="w-10" *ngIf="artikel.type == 'product' && this.type == 'deliverynotes'">
                          <p>
                            {{ artikel.product.productNumber }}
                          </p>
                        </td>
                        <td class="w-45" *ngIf="artikel.type == 'product'">
                          <p class="mb-0">
                            <strong>{{ artikel.product.name }}</strong>
                          </p>
                          <p class="text-secondary pre-wrap">{{ artikel.product.description }}
                          </p>
                        </td>
                        <td class="w-10 text-right" *ngIf="artikel.type == 'product'">
                          <p>
                            {{ artikel.count }} {{ artikel.count > 1 ? artikel.product.unitTypeMultiple :
                            artikel.product.unitTypeSingle }}
                          </p>
                        </td>
                        <td class="w-15 text-right" *ngIf="artikel.type == 'product' &&
                            this.type != 'deliverynotes'">
                          <p>{{ artikel.price | number : "1.2-2" }}€</p>
                        </td>
                        <td class="text-right" *ngIf="this.getDiscount() > 0 &&
                            artikel.type == 'product' &&
                            this.type != 'deliverynotes'">
                          <p>
                            {{ this.getDiscountForItem( artikel.price, artikel.count, artikel.discountValue,
                            artikel.discountType ) | number : "1.2-2" }}€

                            <small *ngIf="artikel.discountType == '%'"><br>
                              {{ artikel.discountValue }}%
                            </small>
                          </p>
                        </td>
                        <td class="w-5 text-right" *ngIf="artikel.type == 'product' &&
                            this.inputData.get('tax')?.value == true &&
                            this.type != 'deliverynotes'">
                          <p>
                            {{ this.getTaxForItem( this.getNettoForItem( artikel.price, artikel.count,
                            artikel.discountValue, artikel.discountType ), artikel.tax ) | number : "1.2-2" }}€
                            <br>
                            <small>{{ artikel.tax }}%</small>
                          </p>
                        </td>
                        <td class="w-10 text-right" *ngIf="artikel.type == 'product' &&
                            this.type != 'deliverynotes'">
                          <p>
                            {{ this.getBruttoForItem( artikel.price, artikel.count, artikel.discountValue,
                            artikel.discountType, artikel.product.tax ) | number : "1.2-2" }}€
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Fußnote -->
              <div class="row mt-3 mt-md-0" *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                <div class="col-md-6 col-12 text-left mt-4">
                  <p class="pre-wrap" *ngIf="this.type == 'offers'">
                    {{ this.inputData.get("footnote")?.value }}
                  </p>
                  <p class="pre-wrap" *ngIf="this.type == 'deliverynotes'">
                    {{ this.inputData.get("deliveryTerms")?.value }}
                  </p>
                  <p class="pre-wrap" *ngIf="this.type == 'revenues'">
                    {{ this.inputData.get("terms")?.value }}
                    <span class="pre-wrap text-muted" *ngIf="this.skonto > 0">
                      <br>{{ this.getSkontoText() }}
                    </span>
                  </p>
                </div>
                <div class="col-md-6 col-12">
                  <div class="table-responsive" *ngIf="this.type != 'deliverynotes'">
                    <table class="table">
                      <tbody>
                        <tr class="nb line-top" *ngIf="this.getDiscount() > 0">
                          <td class="text-bold-500">{{ this.translate("sub-total") }}</td>
                          <td class="text-right">
                            {{ this.getTotal() | number : "1.2-2" }}€
                          </td>
                        </tr>
                        <tr class="nb" *ngIf="this.getDiscount() > 0">
                          <td>{{ this.translate("discount") }}</td>
                          <td class="text-right">
                            {{ this.getDiscount() | number : "1.2-2" }}€
                          </td>
                        </tr>
                        <tr class="nb line-top" *ngIf="this.inputData.get('tax')?.value == true">
                          <td class="text-bold-500">{{ this.translate("net") }}</td>
                          <td class="text-right">
                            {{ this.getNetto() | number : "1.2-2" }}€
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.calcWithSkonto(this.getNetto())
                              | number : "1.2-2"
                              }}€
                            </small>
                          </td>
                        </tr>
                        <tr class="nb" *ngFor="let steuer of this.getIncludedTaxes() | keyvalue">
                          <td *ngIf="this.inputData.get('tax')?.value == true">
                            {{ this.translate("tax") }}
                            <small>{{ steuer.key }}%</small>
                          </td>
                          <td class="text-right" *ngIf="this.inputData.get('tax')?.value == true">
                            {{ steuer.value | number : "1.2-2" }}€
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.calcWithSkonto(steuer.value)
                              | number : "1.2-2"
                              }}€
                            </small>
                          </td>
                        </tr>
                        <tr class="nb line-top text-bold-500">
                          <td>
                            {{ (this.inputData.get("locked").value && (this.overdueFees > 0 || this.overdueInterest >
                            0)) ? this.translate("total") :
                            this.translate("bill-amount") }}
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.translate("with") +
                              " " +
                              this.translate("skonto")
                              }}
                            </small>
                          </td>
                          <td class="text-right">
                            {{ this.getBrutto() | number : "1.2-2" }}€
                            <small *ngIf="this.skonto > 0" class="text-muted">
                              <br>
                              {{
                              this.calcWithSkonto(this.getBrutto())
                              | number : "1.2-2"
                              }}€
                            </small>
                          </td>
                        </tr>
                        <!-- Erweiterung für Mahngebühren-->
                        <tr class="nb line-top" *ngIf="this.overdueInterest > 0">
                          <td>{{ this.translate("module-accounting-overduewarnings-interest") }}</td>
                          <td class="text-right">
                            +{{ (this.getBrutto()/100)*this.overdueInterest | number : "1.2-2" }}€<br>
                            <small>{{ this.overdueInterest | number : "1.2-2" }}%</small>
                          </td>
                        </tr>
                        <tr class="nb" *ngIf="this.overdueFees > 0">
                          <td>{{ this.translate("module-accounting-overduewarnings-fees") }}</td>
                          <td class="text-right">
                            +{{ this.overdueFees | number : "1.2-2" }}€
                          </td>
                        </tr>


                        <tr class="nb line-top text-bold-500" *ngIf="this.overdueFees > 0 || this.overdueInterest > 0">
                          <td>
                            {{ this.translate("bill-amount") }}
                          </td>
                          <td class="text-right">
                            {{ this.getBruttoWithFees() | number : "1.2-2" }}€
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Invoice Items Details ends -->
          </div>

          <div class="card-footer">
            <hr [style]="this.spacerColor()">
            <div class="row">
              <div class="col-4">
                <p [ngClass]="this.footerAlignment('left')" class="pre-wrap">{{ this.footerContent("left").replace(
                  "#[seiten]", "Seite 1 von 1" ) }}
                </p>
              </div>
              <div class="col-4">
                <p [ngClass]="this.footerAlignment('center')" class="pre-wrap">{{ this.footerContent("center").replace(
                  "#[seiten]", "Seite 1 von 1" ) }}
                </p>
              </div>
              <div class="col-4">
                <p [ngClass]="this.footerAlignment('right')" class="pre-wrap">{{ this.footerContent("right").replace(
                  "#[seiten]", "Seite 1 von 1" ) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Primary Create & Edit View -->
<section class="container-fluid" *ngIf="this.mode == 'edit' || this.mode == 'create'">
  <div class="row" [ngClass]="this.dcs.getMenuPosition() == 'Top' ? 'float' : 'float-2'">
    <div class="col-12 text-sm-left">
      <a id="cypress-cancel" [ngbTooltip]="this.translate('cancel')" placement="bottom" class="btn btn-icon round mr-1"
        [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'" (click)="this.cancel()">
        <i class="ft-x"></i>
      </a>
      <a id="cypress-submit"
        [ngbTooltip]="this.mode == 'edit' ? this.translate('submit-changes') : this.type == 'offers' ? this.translate('module-accounting-offers-create') : this.type == 'revenues' ? this.translate('module-accounting-invoices-create') : this.type == 'deliverynotes' ? this.translate('module-storage-deliverynotes-create') : (this.type == 'paymentreminders' ? this.translate('module-accounting-payment-reminders-create') : (this.type == 'overduewarnings' ? this.translate('module-accounting-overduewarnings-create') : this.translate('module-orders-orderconfirmation-create')))"
        placement="bottom" class="btn btn-icon round mr-1"
        [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'"
        (click)="this.mode == 'edit' ? submitEdit() : submitCreation()">
        <i [ngClass]="this.isLoading ? 'ft-loader' : 'ft-check'"></i>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <form [formGroup]="this.inputData">
        <div class="card">
          <div class="card-content p-3">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <img class="thumbnail" [src]="this.companyDetails['logo']" alt="Logo"
                    *ngIf="this.headerContent('left') == '#[logo]'">
                  <p [ngClass]="this.headerAlignment('left')" class="pre-wrap"
                    *ngIf="this.headerContent('left') != '#[logo]'">
                    {{ this.headerContent("left") }}
                  </p>
                </div>
                <div class="col-6">
                  <img [src]="this.companyDetails['logo']" alt="Logo" *ngIf="this.headerContent('right') == '#[logo]'">
                  <p [ngClass]="this.headerAlignment('right')" class="pre-wrap"
                    *ngIf="this.headerContent('right') != '#[logo]'">
                    {{ this.headerContent("right") }}
                  </p>
                </div>
              </div>
              <hr [style]="this.spacerColor()">
            </div>

            <div class="card-body pb-0">
              <!-- Invoice Customer Details starts -->
              <div id="invoice-customer-details" class="row">
                <!-- Absender -->
                <div class="col-12 text-left">
                  <p class="text-muted mb-1 text-custom">
                    {{ companyDetails["company_name"] }} | {{ companyDetails["company_adresse"] }} | {{
                    companyDetails["company_plz"] }} {{ companyDetails["company_ort"] }}
                  </p>
                </div>

                <!-- Kundendaten -->
                <div class="col-md-6 col-12 mb-5">
                  <ul class="m-0 list-unstyled"
                    *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                    <li class="text-bold-800" *ngIf="this.mode != 'edit'">
                      <div class="input-group">
                        <div class="input-group-prepend"><button id="cypress-submit" type="button"
                            class="btn gradient-purple-bliss" (click)="filterCustomers()">
                            {{ this.translate("search") }}
                          </button>
                        </div><input id="cypress-search" type="text" class="form-control" [placeholder]="this.translate(
                                                          'module-crm-value-customername'
                                                        )" (input)="setFilterCustomers($event.target.value)" />
                        <div class="input-group-append"><button type="button" class="btn gradient-purple-bliss"
                            ngbPopover='Schreiben Sie den Namen des gewünschten Kunden in die Suchleiste und klicken Sie auf "Suchen". Wenn Sie mit leerer Suchleiste auf "Suchen" klicken werden alle Kunden geladen.'
                            popoverTitle="Kundensuche" placement="left"><i class="ft-help-circle"></i>
                          </button>
                        </div>
                      </div>
                      <p *ngIf="this.showFilterCustomerError"><small class="danger">Geben Sie einen Kundennamen
                          an!</small></p>

                      <ng-select id="cypress-customerDopdown" class="form-control p2" [items]="customerList"
                        bindLabel="name" (change)="this.selectCustomer($event);"
                        *ngIf="this.mode == 'create' &&
                          this.type != 'orders' &&
                          !this.orderNumber && (this.customerList.length > 0 || this.inputData.get('customerNumber')?.value)">
                      </ng-select>
                    </li>
                    <li *ngIf="this.mode == 'edit' ||
                        this.type == 'orders' ||
                        (this.mode == 'create' &&
                          this.type == 'revenues' &&
                          this.orderNumber)">
                      {{ this.inputData.get("name")?.value }}
                    </li>
                    <li *ngIf="this.inputData.get('forSale')?.value">
                      z.H.
                      <span *ngIf="this.inputData.get('salutation')?.value &&
                          this.inputData.get('salutation')?.value != 'Keine'">{{
                        this.inputData.get("salutation")?.value }}</span> {{ this.inputData.get("forSale")?.value }}
                    </li>
                    <li>{{ this.inputData.get("adress")?.value?.street }}</li>
                    <li>
                      {{ this.inputData.get("adress")?.value?.zip }} {{ this.inputData.get("adress")?.value?.city }}
                    </li>
                    <li>
                      {{ this.inputData.get("adress")?.value?.country }}
                    </li>
                  </ul>

                  <ul class="m-0 list-unstyled"
                    *ngIf="this.type == 'paymentreminders' || this.type == 'overduewarnings'">
                    <li>
                      {{ this.inputData.get("name")?.value }}
                    </li>
                    <li *ngIf="this.inputData.get('forSale')?.value">
                      z.H.
                      <span *ngIf="this.inputData.get('salutation')?.value &&
                                            this.inputData.get('salutation')?.value != 'Keine'">{{
                        this.inputData.get("salutation")?.value }}</span> {{ this.inputData.get("forSale")?.value }}
                    </li>
                    <li>{{ this.inputData.get("adress")?.value?.street }}</li>
                    <li>
                      {{ this.inputData.get("adress")?.value?.zip }} {{ this.inputData.get("adress")?.value?.city }}
                    </li>
                    <li>
                      {{ this.inputData.get("adress")?.value?.country }}
                    </li>
                  </ul>
                </div>
                <!-- /Kundendaten -->

                <div class="col-md-6 col-12 text-right">
                  <p>
                    <span *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                      <span class="text-muted">{{ this.translate("date") }}:
                      </span>
                      <input type="date" class="small-input mb-2" formControlName="date" id="cypress-date"><br>
                    </span>

                    <span *ngIf="this.type == 'offers'">
                      <span class="text-muted">{{
                        this.translate("module-accounting-offers-valid-til")
                        }}:</span>
                      <input type="date" class="small-input mb-2" formControlName="validTil" id="cypress-validTil"><br>
                    </span>

                    <!-- Steuern verrechnen -->
                    <span class="text-muted"
                      *ngIf="this.type != 'deliverynotes' && this.type != 'paymentreminders' && this.type != 'overduewarnings'">{{
                      this.translate("include-taxes") }}:
                      <select class="small-input mb-2" formControlName="tax" id="cypress-tax" *ngIf="this.type != 'deliverynotes'">
                        <option [value]="true">{{ this.translate("yes") }}</option>
                        <option [value]="false">{{ this.translate("no") }}</option>
                      </select><br>
                    </span>

                    <!-- Auftragsnummer -->
                    <span class="mt-2"
                      *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings' && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
                      <span class="text-muted">{{
                        this.translate("module-orders-ordernumber") }}:</span>
                      <input type="text" class="small-input" formControlName="orderNumber">
                    </span>

                    <!-- Rechnungsnummer & Datum wenn ZE oder Mahnung -->
                    <span class="mt-2"
                      *ngIf="this.type == 'paymentreminders' || this.type == 'overduewarnings' && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
                      <span class="text-muted">{{
                        this.translate("bill-amount") }}:</span> {{
                      this.getBrutto() | number: '1.2-2' }}€<br>
                      <span class="text-muted">{{
                        this.translate("module-accounting-invoices-billnumber") }}:</span> {{
                      this.inputData.get('revenueNumber').value }}<br>
                      <span class="text-muted">{{ this.translate("module-accounting-invoices-date") }}:
                      </span>{{
                      this.formatDateReadable(this.inputData.get('date').value) }}<br>
                    </span>

                    <span
                      *ngIf="this.type == 'paymentreminders' && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
                      <br>
                      <span class="text-muted">{{ this.translate("date") }}:
                      </span>
                      <input type="date" class="small-input mb-2" formControlName="reminderDate"
                        id="cypress-paymentreminderDate">
                      <br>
                      <span class="text-muted">{{ this.translate('module-accounting-payment-reminders-paymenttarget')
                        }}:
                      </span>
                      <input type="date" class="small-input mb-2" formControlName="paymentTarget"
                        id="cypress-paymentreminderPaymentTarger">
                    </span>

                    <span
                      *ngIf="this.type == 'overduewarnings' && this.inputData.get('warningDate').value && !this.inputData.get('warningDate2').value && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
                      <br>
                      <span class="text-muted">{{ this.translate("date") }}:
                      </span>
                      <input type="date" class="small-input mb-2" formControlName="warningDate"
                        id="cypress-overduewarningDate">
                      <br>
                      <span class="text-muted">{{ this.translate("module-accounting-payment-reminders-paymenttarget")
                        }}:
                      </span>
                      <input type="date" class="small-input mb-2" formControlName="paymentTarget"
                        (change)="this.setWarningDefaultText()" id="cypress-overduewarningPaymentTarget">
                    </span>
                  </p>

                  <!-- UID & Bearbeiter -->
                  <p>
                    <span class="text-muted">{{ this.translate("uid") }} :</span> {{ this.inputData.get("uid")?.value ==
                    "Nicht angegeben" || this.inputData.get("uid")?.value == "" ? this.translate("not-provided") :
                    this.inputData.get("uid")?.value }}
                    <br>
                    <span class="text-muted">Bearbeiter:</span> {{ this.companyDetails.name }}<br>
                    <span class="text-muted">E-Mail: </span>
                    <a [href]="'mailto:' + this.email">{{ this.email }}</a>
                  </p>

                  <!-- Artikel buchen -->
                  <div class="checkbox float-right"
                    *ngIf="this.mode == 'create' && (this.type == 'revenues' || this.type == 'deliverynotes') && (this.hasPermission('erp-basic') || this.hasPermission('erp-pro'))">
                    <input type="checkbox" id="checkbox1" formControlName="bookProducts"
                      [checked]="this.inputData.get('bookProducts')?.value">
                    <label for="checkbox1"><span>{{ this.translate('module-storage-book-products') }}</span></label>
                  </div>
                </div>
              </div>
              <!-- Invoice Customer Details ends -->

              <!-- Invoice Items Details starts -->
              <div id="invoice-items-details" *ngIf="this.type != 'paymentreminders' && this.type != 'overduewarnings'">
                <h2 *ngIf="this.type == 'revenues'" class="primary text-uppercase title m-2 ml-0">
                  {{ this.translate("module-accounting-invoice") }} {{ this.inputData.get("revenueNumber")?.value }}
                  <small class="generated-note" *ngIf="!this.inputData.get('revenueNumber')?.value">
                    {{
                    this.translate(
                    "module-accounting-invoices-billnumber-generated"
                    )
                    }}
                  </small>
                </h2>

                <!-- Angebotsnummer -->
                <div class="inline" *ngIf="this.type == 'offers'">
                  <h2 class="primary text-uppercase title mb-2 ml-0 mr-2">
                    {{ this.translate("module-accounting-offer") }}
                  </h2>

                  <div class="input-group">
                    <input type="text" id="cypress-offerNumber" formControlName="offerNumber" class="form-control"
                      [placeholder]="this.translate('module-accounting-offers-offernumber')"
                      [ngClass]="!this.inputData.get('offerNumber').valid && (this.inputData.get('offerNumber').dirty || this.inputData.get('offerNumber').touched) ? 'border-error':''"
                      aria-describedby="basic-addon">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" (click)="this.generateNumber()"
                        [disabled]="this.isLoading">{{ this.isLoading ?
                        this.translate('please-wait'):this.translate('generate')}}</button>
                    </div>
                  </div>
                </div>
                <div class="alert alert-danger"
                  *ngIf="!this.inputData.get('offerNumber').valid && (this.inputData.get('offerNumber').dirty || this.inputData.get('offerNumber').touched)">
                  Bitte nur Buchstaben und Zahlen!
                </div>

                <!-- Auftragsnummer AB -->
                <h2 *ngIf="this.type == 'orders'" class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-orders-orderconfirmation") }} {{ this.number }}
                </h2>

                <!-- Lieferschein -->
                <h2 *ngIf="this.type == 'deliverynotes'" class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-storage-deliverynote") }}
                </h2>

                <textarea class="form-control" id="text" rows="1" formControlName="offertext"
                  *ngIf="this.type == 'offers'"></textarea>
                <textarea class="form-control" id="text" rows="1" formControlName="revenuetext"
                  *ngIf="this.type == 'revenues'"></textarea>
                <textarea class="form-control" id="text" rows="5" formControlName="deliveryText"
                  *ngIf="this.type == 'deliverynotes'"></textarea>
                <textarea class="form-control" id="text" rows="5" formControlName="orderText"
                  *ngIf="this.type == 'orders'"></textarea>

                <!-- Leistungentabelle bearbeitbar -->
                <div *ngIf="(this.type != 'orders' && this.type != 'deliverynotes')">
                  <div class="row">
                    <div class="table-responsive col-12 overflow-inherit">
                      <table class="table mt-3">
                        <thead>
                          <tr>
                            <th class="text-center">#</th>
                            <th>
                              {{ this.translate("module-storage-product") }}
                            </th>
                            <th class="text-right">{{ this.translate("count") }}</th>
                            <th class="text-right">
                              {{ this.translate("price") }}
                              <small><br>{{ this.translate("per-unit") }}</small>
                            </th>
                            <th class="text-right">{{ this.translate("discount") }}</th>
                            <th class="text-right"
                              *ngIf="this.inputData.get('tax')?.value == true || this.inputData.get('tax')?.value == 'true'">
                              {{ this.translate("tax") }}
                            </th>
                            <th class="text-right">{{ this.translate("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody formArrayName="products" cdkDropList (cdkDropListDropped)="onDrop($event)">
                          <tr *ngFor="let artikel of this.inputData?.get('products')[
                                'controls'
                              ];
                              let i = index" [formGroupName]="i" cdkDrag cdkDragLockAxis="y">
                            <td class="w-5 text-center" *ngIf="artikel.get('type')?.value == 'product'">
                              {{ this.getPos(i) + 1 }}<br><i class="ft-align-justify move"></i>
                            </td>
                            <td class="text-center" *ngIf="artikel.get('type')?.value == 'pagebreak' ||
                                artikel.get('type')?.value == 'postext'"><i class="ft-align-justify move"></i></td>
                            <td *ngIf="artikel.get('type')?.value == 'pagebreak'">
                              <p class="text-secondary">
                                {{ this.translate("pagebreak") }}
                              </p>
                            </td>
                            <td *ngIf="artikel.get('type')?.value == 'postext'">
                              <input type="text" class="form-control" formControlName="value"
                                [placeholder]="this.translate('postext')">
                            </td>
                            <td *ngIf="artikel.get('type')?.value == 'pagebreak' ||
                                artikel.get('type')?.value == 'postext'"></td>
                            <td *ngIf="artikel.get('type')?.value == 'pagebreak' ||
                                artikel.get('type')?.value == 'postext'"></td>
                            <td *ngIf="artikel.get('type')?.value == 'pagebreak' ||
                                artikel.get('type')?.value == 'postext'"></td>
                            <td *ngIf="artikel.get('type')?.value == 'pagebreak' ||
                                artikel.get('type')?.value == 'postext'"></td>

                            <td class="w-35" *ngIf="artikel.get('type')?.value == 'product'">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <button id="cypress-submit" type="button" class="btn gradient-purple-bliss"
                                    (click)="filterProducts()">
                                    {{ this.translate("search") }}
                                  </button>
                                </div>
                                <input id="cypress-search" type="text" class="form-control"
                                  placeholder="Produkt/Leistung suchen..." (input)="setFilter($event.target.value)">
                                <div class="input-group-append">
                                  <button type="button" class="btn gradient-purple-bliss"
                                    ngbPopover='Schreiben Sie den Namen des gewünschten Artikels in die Suchleiste und klicken Sie auf "Suchen". Wenn Sie mit leerer Suchleiste auf "Suchen" klicken werden alle Artikel geladen.'
                                    popoverTitle="Artikelsuche" placement="right">
                                    <i class="ft-help-circle"></i>
                                  </button>
                                </div>
                              </div>

                              <ng-select id="cypress-serviceDropdown" class="form-control p2" [items]="productsList"
                                bindLabel="name" formControlName="product" (change)="updateProductValues($event, i)"
                                [isOpen]="this.productSelectOpen">
                              </ng-select>
                              <small class="text-secondary">
                                {{
                                artikel.productNumber
                                }}
                              </small>
                              <textarea name="description" formControlName="description" class="form-control mt-2"
                                (change)="handleDesctiptionChange($event, i)"></textarea>
                            </td>
                            <td class="w-10 text-right" *ngIf="artikel.get('type')?.value == 'product'">
                              <input type="number" class="form-control" min="0" formControlName="count"
                                placeholder="Anzahl" required>
                            </td>
                            <td class="w-15 text-right" *ngIf="artikel.get('type')?.value == 'product'">

                              <div class="input-group">
                                <input type="number" class="form-control" min="0" formControlName="price"
                                  [placeholder]="this.translate('price') + ' ' + this.translate('per-unit')" required>
                                <div class="input-group-append">
                                  <span class="input-group-text">€</span>
                                </div>
                              </div>
                            </td>
                            <td class="w-15 text-right" *ngIf="artikel.get('type')?.value == 'product'">
                              <fieldset>
                                <div class="input-group">
                                  <input type="number" class="form-control" min="0" formControlName="discountValue"
                                    [placeholder]="this.translate('discount')" required>
                                  <div class="input-group-append round-left">
                                    <select class="form-control round-left" formControlName="discountType">
                                      <option value="€">€</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </fieldset>
                            </td>
                            <td class="w-15 text-right"
                              *ngIf="artikel.get('type')?.value == 'product' &&
                                this.inputData.get('tax')?.value == true || this.inputData.get('tax')?.value == 'true'">
                              <fieldset>
                                <div class="input-group">
                                  <input type="number" class="form-control" min="0" max="75" formControlName="tax"
                                    [placeholder]="this.translate('tax')" required>
                                  <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                  </div>
                                </div>
                              </fieldset>
                            </td>
                            <td class="w-5 text-right">
                              <button class="btn btn-icon round gradient-pomegranate ml-2 float-right"
                                (click)="removeOrClearService(i)" [ngbTooltip]="this.translate(
                                    'module-storage-product-remove'
                                  )" placement="top">
                                <i class="ft-trash-2"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div ngbDropdown class="d-inline-block add-button mb-2" placement="bottom-right">
                        <button type="button" class="btn btn-icon round gradient-mint" id="add"
                          [ngbTooltip]="this.translate('add')" placement="top" ngbDropdownToggle>
                          <i class="ft-plus"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="moreActions">
                          <a id="cypress-addProduct" class="dropdown-item w100" (click)="this.addServiceFormGroup()">
                            {{ this.translate("module-storage-product-add") }}
                          </a>
                          <a id="cypress-addPostext" class="dropdown-item w100" (click)="this.addPositionText()">
                            {{ this.translate("postext") }}
                          </a>
                          <a id="cypress-addPagebreak" class="dropdown-item w100" (click)="this.addPageBreak()">
                            {{ this.translate("add-pagebreak") }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 mt-md-0">
                    <div class="col-md-6 col-12 text-left mt-4">
                      <div class="row" *ngIf="this.type == 'revenues'">
                        <div class="col-6">
                          <div class="input-group">
                            <input type="number" class="form-control" step="1" min="0" value="0"
                              formControlName="skonto" (change)="this.updateSkonto()">
                            <div class="input-group-append round-right">
                              <span class="input-group-text">% {{ this.translate("skonto") }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="input-group">
                            <div class="input-group-prepend round-right">
                              <span class="input-group-text">{{
                                this.translate("within")
                                }}</span>
                            </div>
                            <input type="number" class="form-control" step="1" min="0" value="0"
                              formControlName="skontotage">
                            <div class="input-group-append round-right">
                              <span class="input-group-text">{{
                                this.translate("days")
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <textarea class="form-control" id="terms" rows="5" formControlName="footnote"
                        *ngIf="this.type == 'offers'"></textarea>
                      <textarea class="form-control" id="terms" rows="5" formControlName="terms"
                        *ngIf="this.type == 'revenues'"></textarea>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr class="nb line-top" *ngIf="this.getDiscount() > 0">
                              <td class="text-bold-500">{{ this.translate("sub-total") }}</td>
                              <td class="text-right">
                                {{ this.getTotal() | number : "1.2-2" }}€
                              </td>
                            </tr>
                            <tr class="nb" *ngIf="this.getDiscount() > 0">
                              <td>{{ this.translate("discount") }}</td>
                              <td class="text-right">
                                {{ this.getDiscount() | number : "1.2-2" }}€
                              </td>
                            </tr>
                            <tr class="nb line-top text-bold-500">
                              <td>{{ (this.inputData.get('tax')?.value == true || this.inputData.get('tax')?.value ==
                                'true') ? this.translate("net"):this.translate("bill-amount") }}</td>
                              <td class="text-right">
                                {{ this.getNetto() | number : "1.2-2" }}€
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.calcWithSkonto(this.getNetto())
                                  | number : "1.2-2"
                                  }}€
                                </small>
                              </td>
                            </tr>
                            <tr class="nb" [ngClass]="this.inputData.get('tax')?.value == true || this.inputData.get('tax')?.value == 'true' ? '':'dpn'" *ngFor="let steuer of this.getIncludedTaxes() | keyvalue">
                              <td>
                                {{ this.translate("tax") }}
                                <small>{{ steuer.key }}%</small>
                              </td>
                              <td class="text-right">
                                {{ steuer.value | number : "1.2-2" }}€
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.calcWithSkonto(steuer.value)
                                  | number : "1.2-2"
                                  }}€
                                </small>
                              </td>
                            </tr>
                            <tr class="nb line-top text-bold-500"
                              *ngIf="this.inputData.get('tax')?.value == true || this.inputData.get('tax')?.value == 'true'">
                              <td>
                                {{ this.translate("bill-amount") }}
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.translate("with") +
                                  " " +
                                  this.translate("skonto")
                                  }}
                                </small>
                              </td>
                              <td class="text-right">
                                {{ this.getBrutto() | number : "1.2-2" }}€
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.calcWithSkonto(this.getBrutto())
                                  | number : "1.2-2"
                                  }}€
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Leistungentabelle nicht bearbeitbar -->
                <div *ngIf="this.type == 'orders'">
                  <div class="table-responsive overflow-inherit">
                    <table class="table mt-3">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {{ this.translate("module-storage-product") }}
                          </th>
                          <th class="text-right">{{ this.translate("count") }}</th>
                          <th class="text-right">
                            {{ this.translate("price") }}
                            <small><br>{{ this.translate("per-unit") }}</small>
                          </th>
                          <th class="text-right" *ngIf="this.getDiscount() > 0">
                            {{ this.translate("discount") }}
                          </th>
                          <th class="text-right" *ngIf="this.inputData.get('tax')?.value == true">
                            {{ this.translate("tax") }}
                          </th>
                          <th class="text-right">{{ this.translate("total") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let artikel of this.inputData?.get('products')
                                                  ?.value;
                                                let i = index">
                          <td class="w-5" *ngIf="artikel.type == 'product'">
                            {{ this.getPos(i) + 1 }}
                          </td>
                          <td class="w-5" *ngIf="artikel.type == 'postext' ||
                                                  artikel.type == 'pagebreak'"></td>
                          <td class="w-45" *ngIf="artikel.type == 'postext'">
                            {{ artikel.value }}
                          </td>
                          <td class="w-45" *ngIf="artikel.type == 'pagebreak'">
                            {{ this.translate("pagebreak") }}
                          </td>

                          <td class="w-10" *ngIf="artikel.type == 'postext' ||
                                                  artikel.type == 'pagebreak'"></td>
                          <td class="w-15" *ngIf="artikel.type == 'postext' ||
                                                  artikel.type == 'pagebreak'"></td>
                          <td *ngIf="(artikel.type == 'postext' &&
                                                    this.getDiscount() > 0) ||
                                                  (artikel.type == 'pagebreak' &&
                                                    this.getDiscount() > 0)"></td>
                          <td class="w-5" *ngIf="(artikel.type == 'postext' ||
                                                    artikel.type == 'pagebreak') &&
                                                  this.inputData.get('tax')?.value == true"></td>
                          <td class="w-10" *ngIf="artikel.type == 'postext' ||
                                                  artikel.type == 'pagebreak'"></td>

                          <td class="w-45" *ngIf="artikel.type == 'product'">
                            <p class="mb-0">
                              <strong>{{ artikel.product.name }}</strong><br>
                              <small>
                                {{
                                artikel.product.productNumber
                                }}
                              </small>
                            </p>
                            <p class="text-secondary pre-wrap">
                              {{ artikel.product.description }}
                            </p>
                          </td>
                          <td class="w-10 text-right" *ngIf="artikel.type == 'product'">
                            <p>{{ artikel.count }} {{ artikel.count > 1 ?
                              artikel.product.unitTypeMultiple:artikel.product.unitTypeSingle }}</p>
                          </td>
                          <td class="w-15 text-right" *ngIf="artikel.type == 'product'">
                            <p>{{ artikel.price | number : "1.2-2" }}€</p>
                          </td>
                          <td class="text-right" *ngIf="this.getDiscount() > 0 &&
                                                  artikel.type == 'product'">
                            <p>
                              {{ artikel.discountValue | number : (artikel.discountType == "%" &&
                              artikel.discountValue > 0 ? "1.0-0" : "1.2-2") }}{{ artikel.discountType == "%" &&
                              artikel.discountValue > 0 ? "%" : "€" }}
                              <small *ngIf="artikel.discountType == '%' &&
                                                      artikel.discountValue > 0"><br>
                                {{
                                this.getDiscountForItem(
                                artikel.price,
                                artikel.count,
                                artikel.discountValue,
                                artikel.discountType
                                ) | number : "1.2-2"
                                }}€
                              </small>
                            </p>
                          </td>
                          <td class="w-5 text-right" *ngIf="artikel.type == 'product' &&
                                                  this.inputData.get('tax')?.value == true">
                            <p>
                              {{ this.getTaxForItem(this.getNettoForItem(artikel.price, artikel.count,
                              artikel.discountValue, artikel.discountType), artikel.product.tax) | number : "1.2-2" }}€
                              <br>
                              <small>{{ artikel.product.tax }}%</small>
                            </p>
                          </td>
                          <td class="w-10 text-right" *ngIf="artikel.type == 'product'">
                            <p>
                              {{ this.getBruttoForItem( artikel.price, artikel.count, artikel.discountValue,
                              artikel.discountType, artikel.product.tax ) | number : "1.2-2" }}€
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="row mt-3 mt-md-0">
                    <div class="col-md-6 col-12 text-left mt-4">
                      <p class="pre-wrap" *ngIf="this.type == 'offers'">
                        {{ this.inputData.get("footnote")?.value }}
                      </p>
                      <p class="pre-wrap" *ngIf="this.type == 'deliverynotes'">
                        {{ this.inputData.get("deliveryTerms")?.value }}
                      </p>
                      <p class="pre-wrap" *ngIf="this.type == 'revenues'">
                        {{ this.inputData.get("terms")?.value }}
                        <span class="pre-wrap text-muted" *ngIf="this.skonto > 0">
                          <br>Bei einer Zahlung {{ this.translate("within") }}
                          {{ this.inputData.get("skontotage")?.value }}
                          {{ this.translate("days") }} gewähren wir
                          {{ this.inputData.get("skonto")?.value }}%
                          {{ this.translate("skonto") }}.
                        </span>
                      </p>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr class="nb line-top" *ngIf="this.getDiscount() > 0">
                              <td class="text-bold-500">{{ this.translate("sub-total") }}</td>
                              <td class="text-right">
                                {{ this.getTotal() | number : "1.2-2" }}€
                              </td>
                            </tr>
                            <tr class="nb" *ngIf="this.getDiscount() > 0">
                              <td>{{ this.translate("discount") }}</td>
                              <td class="text-right">
                                {{ this.getDiscount() | number : "1.2-2" }}€
                              </td>
                            </tr>
                            <tr class="nb line-top" *ngIf="this.inputData.get('tax')?.value == true">
                              <td class="text-bold-500">{{ this.translate("net") }}</td>
                              <td class="text-right">
                                {{ this.getNetto() | number : "1.2-2" }}€
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.calcWithSkonto(this.getNetto())
                                  | number : "1.2-2"
                                  }}€
                                </small>
                              </td>
                            </tr>
                            <tr class="nb" *ngFor="let steuer of this.getIncludedTaxes() | keyvalue">
                              <td *ngIf="this.inputData.get('tax')?.value == true">
                                {{ this.translate("tax") }}
                                <small>{{ steuer.key }}%</small>
                              </td>
                              <td class="text-right" *ngIf="this.inputData.get('tax')?.value == true">
                                {{ steuer.value | number : "1.2-2" }}€
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.calcWithSkonto(steuer.value)
                                  | number : "1.2-2"
                                  }}€
                                </small>
                              </td>
                            </tr>
                            <tr class="nb line-top text-bold-500">
                              <td>
                                {{ this.translate("bill-amount") }}
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.translate("with") +
                                  " " +
                                  this.translate("skonto")
                                  }}
                                </small>
                              </td>
                              <td class="text-right">
                                {{ this.getBrutto() | number : "1.2-2" }}€
                                <small *ngIf="this.skonto > 0" class="text-muted">
                                  <br>
                                  {{
                                  this.calcWithSkonto(this.getBrutto())
                                  | number : "1.2-2"
                                  }}€
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Leistungentabelle für Lieferschein -->
                <div *ngIf="this.type == 'deliverynotes'">
                  <div class="row">
                    <div class="table-responsive col-12 overflow-inherit">
                      <table class="table mt-3">
                        <thead>
                          <tr>
                            <th class="text-center">#</th>
                            <th>
                              {{ this.translate("module-storage-product") }}
                            </th>
                            <th class="text-right">{{ this.translate("count") }}</th>
                            <th class="text-right">{{ this.translate("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody formArrayName="products" cdkDropList (cdkDropListDropped)="onDrop($event)">
                          <tr *ngFor="let artikel of this.inputData?.get('products')[
                                'controls'
                              ];
                              let i = index" [formGroupName]="i" cdkDrag cdkDragLockAxis="y">
                            <td class="w-5 text-center" *ngIf="artikel.get('type')?.value == 'product'">
                              {{ this.getPos(i) + 1 }}<br><i class="ft-align-justify move"></i>
                            </td>
                            <td class="text-center" *ngIf=" artikel.get('type')?.value=='pagebreak' ||
                              artikel.get('type')?.value=='postext'"><i class=" ft-align-justify move"></i></td>

                            <td *ngIf="artikel.get('type')?.value == 'pagebreak'">
                              <p class="text-secondary">
                                {{ this.translate("pagebreak") }}
                              </p>
                            </td>
                            <td *ngIf="artikel.get('type')?.value == 'postext'">
                              <input type="text" class="form-control" formControlName="value"
                                [placeholder]="this.translate('postext')">
                            </td>
                            <td *ngIf="artikel.get('type')?.value == 'pagebreak' ||
                                artikel.get('type')?.value == 'postext'"></td>

                            <td class="w-35" *ngIf="artikel.get('type')?.value == 'product'">
                              <ng-select id="cypress-serviceDropdown" class="form-control p2" [items]="productsList"
                                bindLabel="name" formControlName="product" (change)="updateProductValues($event, i)">
                                <ng-template ng-header-tmp class="p0">
                                  <fieldset>
                                    <div class="input-group mb-0">
                                      <div class="input-group-prepend">
                                        <button id="cypress-submit" type="button" class="btn gradient-purple-bliss"
                                          (click)="filterProducts()">
                                          {{ this.translate("search") }}
                                        </button>
                                      </div>
                                      <input id="cypress-search" type="text" class="form-control"
                                        placeholder="Produkt/Leistung suchen..."
                                        (input)="setFilter($event.target.value)">
                                      <div class="input-group-append">
                                        <button type="button" class="btn gradient-purple-bliss"
                                          ngbPopover='Schreiben Sie den Namen des gewünschten Artikels in die Suchleiste und klicken Sie auf "Suchen". Wenn Sie mit leerer Suchleiste auf "Suchen" klicken werden alle Artikel geladen.'
                                          popoverTitle="Artikelsuche" placement="right">
                                          <i class="ft-help-circle"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </fieldset>
                                </ng-template>
                              </ng-select>
                              <small class="text-secondary">
                                {{
                                artikel.productNumber
                                }}
                              </small>
                              <textarea name="description" formControlName="description" class="form-control mt-2"
                                (change)="handleDesctiptionChange($event, i)"></textarea>
                            </td>
                            <td class="w-10 text-right" *ngIf="artikel.get('type')?.value == 'product'">
                              <input type="number" class="form-control" min="0" formControlName="count"
                                placeholder="Anzahl" required>
                            </td>
                            <td class="w-5 text-right">
                              <button class="btn btn-icon round gradient-pomegranate ml-2 float-right"
                                (click)="removeOrClearService(i)" [ngbTooltip]="this.translate(
                                    'module-storage-product-remove'
                                  )" placement="top">
                                <i class="ft-trash-2"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div ngbDropdown class="d-inline-block add-button mb-2" placement="bottom-right">
                        <button type="button" class="btn btn-icon round gradient-mint" id="add"
                          [ngbTooltip]="this.translate('add')" placement="top" ngbDropdownToggle>
                          <i class="ft-plus"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="moreActions">
                          <a id="cypress-addProduct" class="dropdown-item w100" (click)="this.addServiceFormGroup()">
                            {{ this.translate("module-storage-product-add") }}
                          </a>
                          <a id="cypress-addPostext" class="dropdown-item w100" (click)="this.addPositionText()">
                            {{ this.translate("postext") }}
                          </a>
                          <a id="cypress-addPagebreak" class="dropdown-item w100" (click)="this.addPageBreak()">
                            {{ this.translate("add-pagebreak") }}
                          </a>
                        </div>
                      </div>

                      <textarea class="form-control" id="terms" rows="5" formControlName="deliveryTerms"
                        *ngIf="this.type == 'deliverynotes'"></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div id="invoice-items-details" *ngIf="this.type == 'paymentreminders'">
                <h2 class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-accounting-payment-reminder") }}
                </h2>

                <textarea class="form-control" id="text" rows="10" formControlName="reminderText"></textarea>
              </div>

              <div id="invoice-items-details" *ngIf="this.type == 'overduewarnings'">
                <h2 class="primary text-uppercase title mb-2 ml-0">
                  {{ this.translate("module-accounting-overduewarning") }}
                </h2>

                <textarea class="form-control" id="text" rows="10" formControlName="warningText"></textarea>
              </div>
              <!-- Invoice Items Details ends -->
            </div>

            <div class="card-footer">
              <hr [style]="this.spacerColor()">
              <div class="row">
                <div class="col-4">
                  <p [ngClass]="this.footerAlignment('left')" class="pre-wrap">{{ this.footerContent("left").replace(
                    "#[seiten]", "Seite 1 von 1" ) }}
                  </p>
                </div>
                <div class="col-4">
                  <p [ngClass]="this.footerAlignment('center')" class="pre-wrap">{{
                    this.footerContent("center").replace( "#[seiten]", "Seite 1 von 1" ) }}
                  </p>
                </div>
                <div class="col-4">
                  <p [ngClass]="this.footerAlignment('right')" class="pre-wrap">{{ this.footerContent("right").replace(
                    "#[seiten]", "Seite 1 von 1" ) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<!-- Loading Spinner -->
<section class="spinner-wrapper" *ngIf="this.mode == 'loading'">
  <span class="loader"></span>
  <h2 class="wait-text">{{ this.translate("please-wait") }}</h2>
</section>

<!-- Pup Ups -->
<ng-template #deletePopupOffers let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-accounting-offers-delete')">
    <img class="mb-3" src="assets/img/icons/question.svg">
    <h3>{{ this.translate("module-accounting-offers-delete") }}?</h3>
    <p>{{ this.translate("cant-undo") }}</p>
    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      [disabled]="this.isLoading" (click)="this.deleteDocument()">
      {{
      this.isLoading
      ? this.translate("please-wait")
      : this.translate("delete")
      }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
  </div>
</ng-template>

<ng-template #deletePopupOrders let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-orders-order-delete')">
    <img class="mb-3" src="assets/img/icons/question.svg">
    <h3>{{ this.translate("module-orders-delete") }}?</h3>
    <p>{{ this.translate("cant-undo") }}</p>
    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      [disabled]="this.isLoading" (click)="this.deleteDocument()">
      {{
      this.isLoading
      ? this.translate("please-wait")
      : this.translate("delete")
      }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
  </div>
</ng-template>

<ng-template #deletePopupWarnings let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-accounting-overduewarnings-delete')">
    <img class="mb-3" src="assets/img/icons/question.svg">
    <h3>{{ this.translate("module-accounting-overduewarnings-delete") }}?</h3>
    <p>{{ this.translate("cant-undo") }}</p>
    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      [disabled]="this.isLoading" (click)="this.deleteDocument()">
      {{
      this.isLoading
      ? this.translate("please-wait")
      : this.translate("delete")
      }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
  </div>
</ng-template>

<ng-template #deletePopupReminders let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-accounting-paymentreminders-delete')">
    <img class="mb-3" src="assets/img/icons/question.svg">
    <h3>{{ this.translate("module-accounting-payment-reminders-delete") }}?</h3>
    <p>{{ this.translate("cant-undo") }}</p>
    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      [disabled]="this.isLoading" (click)="this.deleteDocument()">
      {{
      this.isLoading
      ? this.translate("please-wait")
      : this.translate("delete")
      }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
  </div>
</ng-template>

<ng-template #cancleInvoice let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-accounting-revenues-delete')">
    <img class="mb-3" src="assets/img/icons/question.svg">
    <h3>{{ this.translate("module-accounting-invoices-cancel") }}?</h3>
    <p class="mt-1">{{ this.translate("cant-undo") }}</p>

    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      (click)="this.cancelInvoice()">
      {{ this.translate("module-accounting-invoices-cancel") }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
  </div>
</ng-template>

<ng-template #payment let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-accounting-revenues-edit')">
    <h3>
      {{ this.translate("module-accounting-invoices-set-payed") }}
    </h3>
    <p class="mt-1">{{ this.translate("cant-undo") }}</p>

    <div class="row mb-2">
      <div class="col-12"
        *ngIf="!this.inputData.get('paymentreminderID').value && !this.inputData.get('overduewarningID').value">
        <label class="mt-2">{{ this.translate("skonto") }}</label>
        <div class="input-group">
          <input type="number" class="form-control" step="1" min="0" [(ngModel)]="skonto">
          <div class="input-group-append round-right">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="this.inputData.get('overduewarningID').value">
        <label class="mt-2">
          {{ this.translate("module-accounting-overduewarnings-fees") }}
        </label>
        <div class="input-group">
          <input type="number" class="form-control" step=".1" min="0" [(ngModel)]="overdueFees">
          <div class="input-group-append round-right">
            <span class="input-group-text">€</span>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="this.inputData.get('overduewarningID').value">
        <label class="mt-2">{{ this.translate("module-accounting-overduewarnings-interest") }}</label>
        <div class="input-group">
          <input type="number" class="form-control" step=".1" min="0" [(ngModel)]="overdueInterest">
          <div class="input-group-append round-right">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <label class="mt-2">{{ this.translate("date") }}</label>
        <input type="date" class="form-control" [value]="this.payedDate"
          (change)="this.payedDate = $event.target.value">
      </div>
      <div class="col-6">
        <label class="mt-2">{{ this.translate("amount") }}</label>
        <div class="input-group">
          <input type="number" class="form-control" step=".01" min="0" [value]="this.getBruttoWithFees()"
            [disabled]="true">
          <div class="input-group-append round-right">
            <span class="input-group-text">€</span>
          </div>
        </div>
      </div>
    </div>

    <hr>
    <p>{{ this.translate("locked-after") }}</p>

    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.setInvoicePayed()">
      {{ this.translate("submit") }}
    </a>
  </div>
</ng-template>

<ng-template #deleteNote let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-inventory-deliverynotes-delete')">
    <img class="mb-3" src="assets/img/icons/question.svg">
    <h3>
      {{ this.translate("module-storage-deliverynotes-delete") }}
    </h3>
    <p class="mt-1">{{ this.translate("cant-undo") }}</p>

    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      (click)="this.deleteDocument()">
      {{ this.translate("delete") }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.closePopups()">
      {{ this.translate("cancel") }}
    </a>
  </div>
</ng-template>

<ng-template #overdueUpgrade let-modal>
  <div class="modal-body text-center" *ngIf="this.hasPermission('erp-accounting-overduewarnings-edit')">
    <h3>
      {{ this.translate("module-accounting-overduewarnings-upgrade") }}
    </h3>

    <div class="row mb-2">

      <div class="col-6">
        <label class="mt-2">
          {{ this.translate("module-accounting-overduewarnings-fees") }}
        </label>
        <div class="input-group">
          <input type="number" class="form-control" step=".1" min="0" [(ngModel)]="overdueFees"
            (change)="this.setWarningDefaultText2()">
          <div class="input-group-append round-right">
            <span class="input-group-text">€</span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <label class="mt-2">{{ this.translate("module-accounting-overduewarnings-interest") }}</label>
        <div class="input-group">
          <input type="number" class="form-control" step="1" min="0" [(ngModel)]="overdueInterest"
            (change)="this.setWarningDefaultText2()">
          <div class="input-group-append round-right">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <label class="mt-2">{{ this.translate("date") }}</label>
        <input type="date" class="form-control" [value]="this.inputData.get('warningDate2').value"
          (change)="this.inputData.get('warningDate2').patchValue($event.target.value)">
      </div>

      <div class="col-6">
        <label class="mt-2">{{ this.translate("module-accounting-payment-reminders-paymenttarget") }}</label>
        <input type="date" class="form-control" [value]="this.inputData.get('paymentTarget2').value"
          (change)="this.setPaymentTargetAndWarningText2Default($event.target.value)">
      </div>

      <div class="col-12">
        <label class="mt-2">{{ this.translate("module-accounting-overduewarnings-text") }}</label>
        <textarea class="form-control" id="text" rows="12" [value]="this.inputData.get('warningText2').value"
          (change)="this.inputData.get('warningText2').patchValue($event.target.value)"></textarea>
      </div>
    </div>

    <a class="btn mr-3" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-pomegranate'"
      (click)="this.closeWarningPopup()">
      {{ this.translate("cancel") }}
    </a>
    <a class="btn" [ngClass]="this.isLoading ? 'gradient-blackberry' : 'gradient-mint'" [disabled]="this.isLoading"
      (click)="this.upgradeOverduewarning()">
      {{ this.translate("submit") }}
    </a>
  </div>
</ng-template>