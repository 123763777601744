import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DataCacheService } from "app/shared/services/datacache.service";
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'help-tour',
  templateUrl: './help-tour.component.html',
  styleUrls: ['./help-tour.component.scss']
})
export class HelpTourComponent {

  constructor(
    private router: Router,
    private dcs: DataCacheService,
    private lang: TranslationService
  ) { }

  hasPermission(permissionString): boolean {
    const parts = permissionString.split("-");
    const perms = JSON.parse(this.dcs.load("permissions"));

    if (parts.length == 3) {
      return perms[parts[0]][parts[1]][parts[2]];
    } else if (parts.length == 2) {
      return perms[parts[0]][parts[1]];
    } else {
      return perms[permissionString];
    }
  }

  getGuideByRoute() {
    let route = this.router.url;

    if (route === '/buchhaltung/einnahmen/erstellen') {
      this.router.navigateByUrl("/support/helpdesk/rechnung-erstellen");
    } else {
      this.router.navigateByUrl("/support");
    }
  }

  translate(str: string) {
    return this.lang.translate(str);
  }

}
