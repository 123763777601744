import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'kundenverwaltung',
    loadChildren: () => import('../../modules/crm/crm.module').then(m => m.CrmModule)
  },
  {
    path: 'aufträge',
    loadChildren: () => import('../../modules/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'buchhaltung',
    loadChildren: () => import('../../modules/buchhaltung/buchhaltung.module').then(m => m.BuchhaltungModule)
  },
  {
    path: 'warenwirtschaft',
    loadChildren: () => import('../../modules/warenwirtschaft/warenwirtschaft.module').then(m => m.WarenwirtschaftModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../modules/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'dms',
    loadChildren: () => import('../../modules/dms/dms.module').then(m => m.DMSModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'kanban',
    loadChildren: () => import('../../modules/kanban/kanban.module').then(m => m.KanbanModule)
  }
];
